import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../setupStore';

const initialState = {
  value: false,
};

export const DetectDataIntegrationFailSlice = createSlice({
  name: 'dataIntegrationFail',
  initialState,
  reducers: {
    detectDataIntegrationFail: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { detectDataIntegrationFail } =
  DetectDataIntegrationFailSlice.actions;

export const selectDetectDataIntegrationFail = (state: RootState) =>
  state.detectDataIntegrationFail?.value;

export default DetectDataIntegrationFailSlice.reducer;
