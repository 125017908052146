import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from 'react-router-dom';

export interface PrivateRouteParams extends RouteProps {
  id: string;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const conditionLoadingsRouter = (
  initialized: boolean,
  masterLoaded: boolean,
  tenantLoaded: boolean,
  authenticated: boolean,
  isSignStatusFetching: boolean
) =>
  !initialized ||
  (!masterLoaded && !tenantLoaded && authenticated) ||
  isSignStatusFetching;

export const conditionCreationProcess = (
  creationProcess: any,
  signStatus: any
) => {
  if (creationProcess) {
    localStorage.setItem('creationProcess', 'true');
    return true;
  }
  if (signStatus && signStatus.status === 'SIGNED') {
    localStorage.setItem('creationProcess', 'false');
    return false;
  }
  if (signStatus && signStatus.status === 'UNSIGNED') {
    localStorage.setItem('creationProcess', 'true');
    return true;
  }
  localStorage.setItem('creationProcess', creationProcess ? 'true' : 'false');
  return creationProcess;
};

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteParams) {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({});
  }, [location, trackPageView]);

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/unauthorized',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
