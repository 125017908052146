/**
 * inspired by https://usehooks.com/useLocalStorage/ ^_^
 * */

import { useState } from 'react';

const useSessionStorage = (
  key: string,
  initialValue: object | null,
  useSession: boolean = true,
) => {
  const storageType = useSession ? window.sessionStorage : window.localStorage;
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = storageType.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        storageType.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.warn('useSessionStorage error : ', error);
    }
  };
  return [storedValue, setValue];
};

export default useSessionStorage;
