import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../setupStore';
import { ParamReplanNotificationState } from '../../../interfaces/ParamReplanNotificationState';

const initialState: ParamReplanNotificationState = {
  uploadType: null,
  master: null,
};

export const ParamsReplanNotificationSlice = createSlice({
  name: 'paramsReplanNotification',
  initialState,
  reducers: {
    setParamsReplanNotification: (state, action) => {
      state.uploadType = action.payload.uploadType;
      state.master = action.payload.masterRef;
    },
  },
});

export const { setParamsReplanNotification } =
  ParamsReplanNotificationSlice.actions;

export const selectParamsReplanNotification = (state: RootState) =>
  state.paramsReplanNotification;

export default ParamsReplanNotificationSlice.reducer;
