import { Spinner } from 'react-bootstrap';
import React from 'react';

const LoadingDots = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '250px',
        gap: '4px',
      }}
    >
      <Spinner className="spinner" animation="grow" size="sm" />
      <Spinner
        className="spinner"
        animation="grow"
        size="sm"
        style={{ animationDelay: '.1s' }}
      />
      <Spinner
        className="spinner"
        animation="grow"
        size="sm"
        style={{ animationDelay: '.2s' }}
      />
    </div>
  );
};

export default LoadingDots;
