import { useKeycloak } from '@react-keycloak/web';
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import SwitchLang from '../shared/components/switch-lang';
import {
  useGetMasterForCurrentUserQuery,
  useGetMeQuery,
  useGetTenantQuery,
} from '../shared/services/meApi';
import LoadingsRouter from './loadings-router';
import { conditionCreationProcess } from './utils';
import { SignProofStatus } from '../shared/interfaces/SignProofStatus';
import useIsMobile from '../shared/hook/useIsMobile';

const RoutesMasterCreated = lazy(() => import('./routes-master-created'));
const RoutesMasterNotCreated = lazy(
  () => import('./routes-master-not-created')
);
const RoutesNotAuthenticated = lazy(() => import('./routes-not-authenticated'));
const NavBarBottom = lazy(() => import('../shared/components/nav-bar-bottom'));

export const AppRouter = () => {
  const { initialized, keycloak } = useKeycloak();
  const [skipQuery, setSkipQuery] = useState<boolean>(true);
  const [signStatus, setSignStatus] =
    useState<SignProofStatus | undefined>(undefined);
  const [signStatusFetching, setSignStatusFetching] = useState<boolean>(false);
  const { data: user, isSuccess: userLoaded } = useGetMeQuery(undefined, {
    skip: skipQuery,
  });
  const { data: tenant } = useGetTenantQuery(undefined, {
    skip: skipQuery,
  });

  const { data: master, isSuccess: masterLoaded } =
    useGetMasterForCurrentUserQuery(
      { qp: new URLSearchParams({ size: '1' }).toString() },
      { skip: tenant?.creationProcess || !userLoaded }
    );

  useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      setSkipQuery(false);
    }
  }, [initialized, keycloak]);

  const isMobile = useIsMobile();

  const isCreationProcess = useCallback(() => {
    return conditionCreationProcess(tenant?.creationProcess, signStatus);
  }, [signStatus, tenant?.creationProcess]);

  if (
    !initialized ||
    signStatusFetching ||
    (!masterLoaded && !tenant?.creationProcess && keycloak?.authenticated)
  ) {
    return (
      <LoadingsRouter
        masterFetched={masterLoaded}
        initialized={initialized}
        keycloak={keycloak}
      />
    );
  }

  if (!keycloak?.authenticated) {
    return (
      <Router>
        <Suspense fallback={'...'}>
          <RoutesNotAuthenticated />
        </Suspense>
      </Router>
    );
  }

  return (
    <Router>
      <Suspense fallback={'...'}>
        {isCreationProcess() ? (
          <RoutesMasterNotCreated
            setSignStatus={setSignStatus}
            setSignStatusFetching={setSignStatusFetching}
          />
        ) : (
          <RoutesMasterCreated
            master={master}
            setSignStatus={setSignStatus}
            setSignStatusFetching={setSignStatusFetching}
          />
        )}
        {!isMobile && <SwitchLang user={user} />}

        {isMobile && !isCreationProcess() && <NavBarBottom />}
      </Suspense>
    </Router>
  );
};
