import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import './assets/css/App.scss';
import './assets/css/skeleton-loading.scss';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { _setupStore } from './shared/state/setupStore';
import { createRoot } from 'react-dom/client';

const query: boolean = window.location.toString().includes('app.actipay.fr');
const instance = createInstance({
  urlBase: '//tracking.intractiv.com',
  siteId: query ? 4 : 1,
  linkTracking: false,
  configurations: {
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <>
    <MatomoProvider value={instance} />
    <Provider store={_setupStore}>
      <App />
    </Provider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
