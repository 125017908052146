import { createApi } from '@reduxjs/toolkit/query/react';
import { CompanyInformation } from '../interfaces/company-information';
import { ISignContract } from '../interfaces/ISignContract';
import { Master } from '../interfaces/master';
import { StatisticsOperational } from '../interfaces/statistics-operational';
import User from '../interfaces/user';
import AccountUserInfo from '../interfaces/userInfo';
import baseQueryOptions from './baseQueryOptions';
import Subscription from '../interfaces/subscription';
import { Mapping } from '../interfaces/mapping';
import Tenant from '../interfaces/tenant';
import { SignProofStatus } from '../interfaces/SignProofStatus';
import { CompanySearch } from '../interfaces/companySearch';
import { CompanyInformationDetail } from '../interfaces/company-information-detail';
import { PortfolioItem } from '../interfaces/PortfolioItem';
import { AlertItem } from '../interfaces/alert-item';
import { KycVerifyResponse } from '../interfaces/kyc-verify-response';

// Define a service using a base URL and expected endpoints
export const meApi = createApi({
  reducerPath: 'meApi',
  baseQuery: baseQueryOptions,
  tagTypes: [
    'User',
    'UserInfo',
    'MasterInfo',
    'MasterFinancialInfo',
    'Mapping',
    'LastSuccess',
    'Tenant',
    'Subscriptions',
    'CompanySearch',
    'DetailCompanyInfo',
    'Portfolio',
    'Tiers',
    'Alert',
    'Cases',
    'Case',
    'CasesStatistics',
  ],
  endpoints: (builder) => ({
    deleteCompanyLogo: builder.mutation<
      any,
      { masterRef: string; logoUuid: string }
    >({
      query: (queryArgs: { masterRef: string; logoUuid: string }) => ({
        url: `/api/masters/${queryArgs.masterRef}/companyLogo/${queryArgs.logoUuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MasterInfo'],
    }),
    deleteFile: builder.mutation<
      any,
      { masterRef: string; fileUuid: string; type: string }
    >({
      query: (queryArgs: {
        masterRef: string;
        fileUuid: string;
        type: string;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/${queryArgs.type}/${queryArgs.fileUuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MasterInfo'],
    }),
    deleteFileById: builder.mutation<any, { fileId: string }>({
      query: (queryArgs: { fileId: string }) => ({
        url: `/api/files/${queryArgs.fileId}`,
        method: 'DELETE',
      }),
    }),
    getMe: builder.query<User, void>({
      query: () => `/api/me`,
      providesTags: ['User'],
    }),
    getTenant: builder.query<Tenant, void>({
      query: () => `/api/tenant`,
      providesTags: ['Tenant'],
    }),
    patchTenant: builder.mutation<any, { body: any }>({
      query: (queryArgs: { body: any }) => ({
        url: `/api/tenants`,
        method: 'PATCH',
        body: queryArgs.body,
      }),
      invalidatesTags: ['Tenant'],
    }),
    getMasterForCurrentUser: builder.query<Master, { qp: string }>({
      query: ({ qp }: { qp: string }) => `/api/masters?${qp}`,
      transformResponse: ({
        items,
        totalItems,
      }: {
        items: any[];
        totalItems: number;
      }) => (totalItems === 0 ? null : items[0]),
    }),
    getStatisticOperationalForMaster: builder.query<
      StatisticsOperational,
      { masterRef: string }
    >({
      keepUnusedDataFor: 3,
      query: ({ masterRef }: { masterRef: string }) =>
        `/api/masters/${masterRef}/statistics/operational`,
    }),
    getStatisticForMaster: builder.query<
      any,
      { masterRef: string; qp: string }
    >({
      keepUnusedDataFor: 3,
      query: ({ masterRef, qp }: { masterRef: string; qp: string }) =>
        `/api/masters/${masterRef}/statistics?${qp}`,
    }),
    getStatisticsByNameForMaster: builder.query<
      number,
      { masterRef: string; statisticName: string }
    >({
      query: ({
        masterRef,
        statisticName,
      }: {
        masterRef: string;
        statisticName: string;
      }) => `/api/masters/${masterRef}/collection/statistics/${statisticName}`,
      transformResponse: ({ value }: { value: number }) => value,
    }),
    quickSearch: builder.query<any, { searchTerm: string | undefined }>({
      query: ({ searchTerm }: { searchTerm: string | undefined }) =>
        `/api/search?query=${searchTerm}`,
      keepUnusedDataFor: 60,
      transformResponse: ({ content }: { content: any[] }) => content,
    }),
    searchCompany: builder.query<
      { content: CompanySearch[] },
      { queryParams: string }
    >({
      query: ({ queryParams }: { queryParams: string }) =>
        `/api/company-search?${queryParams}`,
      providesTags: ['CompanySearch'],
    }),
    getUser: builder.query<AccountUserInfo, { userId: string }>({
      query: ({ userId }: { userId: string }) => `/api/users/${userId}`,
      providesTags: ['UserInfo'],
    }),
    getMaster: builder.query<any, { masterRef: string; qp?: string }>({
      query: ({ masterRef, qp }: { masterRef: string; qp?: string }) =>
        `/api/masters/${masterRef}?${qp}`,
      providesTags: ['MasterInfo'],
    }),
    getSubscription: builder.query<
      { items: Subscription[]; totalItems: number },
      {
        qp: string;
      }
    >({
      query: (queryArgs: { masterRef: string; qp: string }) =>
        `/api/subscriptions?${queryArgs.qp}`,
      providesTags: ['Subscriptions'],
    }),
    cancelSubscription: builder.mutation<any, void>({
      query: () => ({
        url: `/api/subscriptions/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    getSignStatus: builder.mutation<SignProofStatus, void>({
      query: () => ({
        url: `/api/sign/documents/status`,
        method: 'POST',
      }),
      invalidatesTags: ['User', 'UserInfo', 'Tenant'],
    }),
    getCurentMapping: builder.query<
      any,
      { masterRef: string; mappedEntityType: string }
    >({
      query: ({
        masterRef,
        mappedEntityType,
      }: {
        masterRef: string;
        mappedEntityType: string;
      }) =>
        `/api/masters/${masterRef}/data-integration/mappings/${mappedEntityType}`,
      providesTags: ['Mapping'],
    }),
    getMasterAvailability: builder.query<
      {
        available: boolean;
        email: string;
      },
      { masterRef: string }
    >({
      query: ({ masterRef }: { masterRef: string }) =>
        `/api/tenants/available/master/${masterRef}`,
    }),
    getCase: builder.query<any, { masterRef: string; eventId: string }>({
      query: ({ masterRef, eventId }: { masterRef: string; eventId: string }) =>
        `/api/masters/${masterRef}/cases/${eventId}`,
      providesTags: ['Case'],
    }),
    getMasterFinancialInformation: builder.query<
      CompanyInformation,
      { legalId: string; isOnboarding?: boolean }
    >({
      query: ({
        legalId,
        isOnboarding = false,
      }: {
        legalId: string;
        isOnboarding: boolean;
      }) =>
        `/api/company-information?legalId=${legalId}&isOnboarding=${isOnboarding}`,
      providesTags: ['MasterFinancialInfo'],
    }),
    getDetailCompanyInfo: builder.query<
      CompanyInformationDetail,
      { countryCode: string; ids: string }
    >({
      query: ({ countryCode, ids }: { countryCode: string; ids: string }) =>
        `/api/company-information/detail?countryCode=${countryCode}&${ids}`,
      providesTags: ['DetailCompanyInfo'],
    }),
    getPortfolio: builder.query<
      { items: PortfolioItem[]; totalItems: number },
      { qp: string }
    >({
      query: (queryArgs: { qp: string }) => `/api/portfolio?${queryArgs.qp}`,
      providesTags: ['Portfolio'],
    }),
    getListAlerts: builder.query<
      { items: AlertItem[]; totalItems: number },
      {
        qp: string;
      }
    >({
      query: (queryArgs: { qp: string }) => `/api/alerts?${queryArgs.qp}`,
      providesTags: ['Alert'],
    }),
    getValidationPayment: builder.query<any, { transactionNumber?: string }>({
      query: ({ transactionNumber }: { transactionNumber?: string }) =>
        `/api/orders/order?transactionNumber=${transactionNumber}`,
    }),
    getListInvoices: builder.query<any, { qp?: string }>({
      query: (queryArgs: { qp: string }) => `/api/invoices?${queryArgs.qp}`,
    }),
    changeUser: builder.mutation<
      any,
      { user: Partial<AccountUserInfo>; userId: string | undefined }
    >({
      query: (queryArgs: {
        user: AccountUserInfo;
        userId: string | undefined;
      }) => ({
        url: `/api/users/${queryArgs.userId}`,
        method: 'PATCH',
        body: queryArgs.user,
      }),
      invalidatesTags: ['UserInfo'],
    }),

    changeMaster: builder.mutation<
      any,
      {
        masterRef: string;
        master: Master;
        params?: string;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        master: Master;
        params?: string;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}${
          queryArgs.params ? '?' + queryArgs.params : ''
        }`,
        method: 'PUT',
        body: queryArgs.master,
      }),
      invalidatesTags: ['MasterInfo', 'User', 'Tenant'],
    }),

    accountVersion: builder.mutation<
      any,
      { masterRef: string; contentBody: any }
    >({
      query: (queryArgs: { masterRef: string; contentBody: any }) => ({
        url: `/api/masters/${queryArgs.masterRef}/account-version`,
        method: 'PATCH',
        body: queryArgs.contentBody,
      }),
      invalidatesTags: ['User', 'UserInfo', 'MasterInfo'],
    }),
    generateContract: builder.mutation<any, { masterRef: string }>({
      query: (queryArgs: { masterRef: string }) => ({
        url: `/api/masters/${queryArgs.masterRef}/contracts`,
        method: 'POST',
        responseHandler: (response: Response) =>
          Promise.resolve(response.headers.get('location')),
        body: {},
      }),
    }),
    changeSubscriptionPricing: builder.mutation<any, { duration: string }>({
      query: (queryArgs: { duration: string }) => ({
        url: `api/subscriptions/pricing?duration=${queryArgs.duration}`,
        method: 'POST',
      }),
    }),
    updateCurrentMapping: builder.mutation<
      any,
      { masterRef: string; mappedEntityType: string; mapping: Mapping }
    >({
      query: (queryArgs: {
        masterRef: string;
        mappedEntityType: string;
        mapping: Mapping;
      }) => ({
        url: `api/masters/${queryArgs.masterRef}/data-integration/mappings/${queryArgs.mappedEntityType}`,
        method: 'PUT',
        body: queryArgs.mapping,
      }),
      invalidatesTags: ['Mapping', 'LastSuccess'],
    }),
    ignoredAlert: builder.mutation<any, { alertId: number; contentBody: any }>({
      query: (queryArgs: {
        alertId: number | undefined;
        contentBody: any;
      }) => ({
        url: `/api/alerts/${queryArgs.alertId}`,
        method: 'PATCH',
        body: queryArgs.contentBody,
      }),
      invalidatesTags: ['Alert'],
    }),
    payment: builder.mutation<
      any,
      { masterRef: string; type: string; contentBody: any }
    >({
      query: (queryArgs: {
        masterRef: string;
        type: string;
        contentBody: any;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/buyables/${queryArgs.type}/pay`,
        method: 'POST',
        body: queryArgs.contentBody,
      }),
    }),
    generateSignUrl: builder.mutation<
      { signingUrl: string; documentId: string },
      { fileId: string; body: ISignContract; method: string }
    >({
      query: (queryArgs: {
        fileId: string;
        body: ISignContract;
        method: string;
      }) => ({
        url: `/api/sign/files/${queryArgs.fileId}`,
        method: queryArgs.method,
        body: queryArgs.body,
      }),
    }),
    getLastSuccessMovement: builder.query<any, { masterRef: string }>({
      query: ({ masterRef }: { masterRef: string }) =>
        `/api/masters/${masterRef}/data-integration/movements/last-success`,
      providesTags: ['LastSuccess'],
      keepUnusedDataFor: 10,
    }),
    getLastSuccessTiers: builder.query<any, { masterRef: string }>({
      query: ({ masterRef }: { masterRef: string }) =>
        `/api/masters/${masterRef}/data-integration/tiers/last-success`,
      providesTags: ['LastSuccess'],
      keepUnusedDataFor: 10,
    }),
    getCases: builder.query<
      any,
      { masterRef: string; qp?: string; sessionId: any }
    >({
      query: ({ masterRef, qp }: { masterRef: string; qp?: string }) =>
        `/api/masters/${masterRef}/cases?${qp}`,
      providesTags: ['Cases'],
    }),
    getCasesStatistics: builder.query<
      any,
      { masterRef: string; lastDays: string }
    >({
      query: ({
        masterRef,
        lastDays,
      }: {
        masterRef: string;
        lastDays: string;
      }) => `/api/masters/${masterRef}/cases/statistics/last-days/${lastDays}`,
      providesTags: ['CasesStatistics'],
    }),
    contactUs: builder.mutation<
      void,
      {
        email: string;
        priority: string;
        subject: string;
        body: string;
        userInfo: any;
        language: string;
      }
    >({
      query: (queryArgs: {
        email: string;
        priority: string;
        subject: string;
        body: string;
        userInfo: any;
        language: string;
      }) => ({
        headers: { 'Accept-language': queryArgs.language },
        url: `/api/contact-us`,
        method: 'POST',
        body: {
          email: queryArgs.email,
          priority: queryArgs.priority,
          subject: queryArgs.subject,
          body: queryArgs.body,
          userInfo: queryArgs.userInfo,
        },
      }),
    }),
    resetCredentials: builder.mutation<void, { userUUID: string }>({
      query: (queryArgs: { userUUID: string }) => ({
        url: `/api/users/${queryArgs.userUUID}/reset-credentials`,
        method: 'POST',
      }),
    }),
    generateKycUrl: builder.mutation<
      { uid: string; url: string; shortenedLink: string },
      { masterRef: string }
    >({
      query: ({ masterRef }: { masterRef: string }) => ({
        url: `/api/masters/${masterRef}/kyc/process/start`,
        method: 'POST',
      }),
    }),
    verifyKycProcess: builder.mutation<
      KycVerifyResponse,
      { masterRef: string }
    >({
      query: ({ masterRef }: { masterRef: string }) => ({
        url: `/api/masters/${masterRef}/kyc/process/verify`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useDeleteCompanyLogoMutation,
  useDeleteFileMutation,
  useDeleteFileByIdMutation,
  useGetMeQuery,
  useGetTenantQuery,
  usePatchTenantMutation,
  useGetCaseQuery,
  useGetMasterForCurrentUserQuery,
  useGetStatisticOperationalForMasterQuery,
  useGetStatisticForMasterQuery,
  useGetStatisticsByNameForMasterQuery,
  useQuickSearchQuery,
  useSearchCompanyQuery,
  useGetUserQuery,
  useGetSubscriptionQuery,
  useCancelSubscriptionMutation,
  useGetSignStatusMutation,
  useGetMasterQuery,
  useGetCurentMappingQuery,
  useGetListAlertsQuery,
  useGetMasterAvailabilityQuery,
  useGetListInvoicesQuery,
  useGetMasterFinancialInformationQuery,
  useGetDetailCompanyInfoQuery,
  useGetPortfolioQuery,
  useGetValidationPaymentQuery,
  useChangeUserMutation,
  useChangeMasterMutation,
  useGenerateContractMutation,
  useChangeSubscriptionPricingMutation,
  usePaymentMutation,
  useGenerateSignUrlMutation,
  useUpdateCurrentMappingMutation,
  useGetLastSuccessMovementQuery,
  useGetLastSuccessTiersQuery,
  useIgnoredAlertMutation,
  useGetCasesQuery,
  useGetCasesStatisticsQuery,
  useAccountVersionMutation,
  useContactUsMutation,
  useResetCredentialsMutation,
  useGenerateKycUrlMutation,
  useVerifyKycProcessMutation,
} = meApi;
