import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryOptions from './baseQueryOptions';
import {
  CaseData,
  CaseInvoice,
  CaseInvoiceStandard,
} from '../interfaces/CaseData';
import {
  CaseMassDelegation,
  InitCase,
  InitCaseLightMovements,
} from '../interfaces/MassDelegation';

export const caseApi = createApi({
  reducerPath: 'caseApi',
  baseQuery: baseQueryOptions,
  tagTypes: ['DraftCase', 'MassDelegation'],
  endpoints: (builder) => ({
    getCaseTiers: builder.query<
      any,
      {
        masterRef: string;
        userUUID?: string;
      }
    >({
      query: (queryArgs: { masterRef: string; userUUID?: string }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/case`,
      }),
      providesTags: ['DraftCase'],
    }),
    getCaseHistories: builder.query<
      any,
      {
        masterRef: string;
        qp: string;
      }
    >({
      query: (queryArgs: { masterRef: string; qp: string }) => ({
        url: `/api/masters/${queryArgs.masterRef}/cases/histories?${queryArgs.qp}`,
      }),
      providesTags: ['DraftCase'],
    }),
    convertCase: builder.mutation<
      any,
      {
        masterRef: string;
        userUUID?: string;
        body: any;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        userUUID?: string;
        body: any;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/case/convert`,
        method: 'POST',
        body: queryArgs.body,
      }),
      invalidatesTags: ['DraftCase'],
    }),
    deleteDraft: builder.mutation<
      void,
      { masterRef: string; userUUID: string }
    >({
      query: (queryArgs: { masterRef: string; userUUID: string }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/case`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DraftCase'],
    }),
    upsertCase: builder.mutation<
      CaseData,
      {
        masterRef: string;
        userUUID?: string;
        body: any;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        userUUID?: string;
        body: any;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/case`,
        method: 'PUT',
        body: queryArgs.body,
      }),
      invalidatesTags: ['DraftCase'],
    }),
    initMassDelegation: builder.mutation<
      CaseMassDelegation,
      {
        masterRef: string;
        userUUID?: string;
        tiersReferences: InitCase;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        userUUID?: string;
        tiersReferences: InitCase;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/mass-delegation/init`,
        method: 'POST',
        body: queryArgs.tiersReferences,
      }),
      invalidatesTags: ['MassDelegation'],
    }),
    initMassDelegationLight: builder.mutation<
      CaseMassDelegation,
      {
        masterRef: string;
        userUUID?: string;
        tiersRefsMovements: InitCaseLightMovements;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        userUUID?: string;
        tiersRefsMovements: InitCaseLightMovements;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/mass-delegation/init-light`,
        method: 'POST',
        body: {
          ...queryArgs.tiersRefsMovements,
        },
      }),
      invalidatesTags: ['MassDelegation'],
    }),
    getMassDelegation: builder.query<
      CaseMassDelegation,
      {
        masterRef: string;
        userUUID?: string;
      }
    >({
      query: (queryArgs: { masterRef: string; userUUID: string }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/mass-delegation`,
      }),
      providesTags: ['MassDelegation'],
    }),
    updateMassDelegation: builder.mutation<
      CaseMassDelegation,
      {
        masterRef: string;
        userUUID?: string;
        body: CaseMassDelegation;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        userUUID?: string;
        body: CaseMassDelegation;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/mass-delegation`,
        method: 'PUT',
        body: queryArgs.body,
      }),
      invalidatesTags: ['MassDelegation'],
    }),
    convertMassDelegation: builder.mutation<
      void,
      {
        masterRef: string;
        userUUID?: string;
      }
    >({
      query: (queryArgs: { masterRef: string; userUUID?: string }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/mass-delegation/convert`,
        method: 'POST',
      }),
      invalidatesTags: ['MassDelegation'],
    }),
    removeTiers: builder.mutation<
      CaseMassDelegation,
      {
        masterRef: string;
        tiersReference: string;
        userUUID?: string;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        tiersReference: string;
        userUUID?: string;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/users/${queryArgs.userUUID}/drafts/mass-delegation/remove-tiers/${queryArgs.tiersReference}`,
        method: 'PUT',
      }),
      invalidatesTags: ['MassDelegation'],
    }),
    addInvoices: builder.mutation<
      CaseInvoice | CaseInvoiceStandard,
      {
        masterRef: string;
        eventId: string;
        light?: boolean;
        body: CaseInvoice | CaseInvoiceStandard;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        eventId: string;
        light?: boolean;
        body: CaseInvoice | CaseInvoiceStandard;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/cases/${
          queryArgs.eventId
        }/add-invoices${queryArgs.light ? '-light' : ''}`,
        method: 'POST',
        body: queryArgs.body,
      }),
      invalidatesTags: ['DraftCase'],
    }),
    addInvoicesLight: builder.mutation<
      CaseInvoice,
      {
        masterRef: string;
        eventId: string;
        body: CaseInvoice;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        eventId: string;
        body: CaseInvoice;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/cases/${queryArgs.eventId}/add-invoices-light`,
        method: 'POST',
        body: queryArgs.body,
      }),
      invalidatesTags: ['DraftCase'],
    }),
    addInvoicesStandard: builder.mutation<
      CaseInvoiceStandard,
      {
        masterRef: string;
        eventId: string;
        body: CaseInvoiceStandard;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        eventId: string;
        body: CaseInvoiceStandard;
      }) => ({
        url: `/api/masters/${queryArgs.masterRef}/cases/${queryArgs.eventId}/add-invoices`,
        method: 'POST',
        body: queryArgs.body,
      }),
    }),
  }),
});

export const {
  useGetCaseTiersQuery,
  useGetCaseHistoriesQuery,
  useConvertCaseMutation,
  useDeleteDraftMutation,
  useUpsertCaseMutation,
  useInitMassDelegationMutation,
  useInitMassDelegationLightMutation,
  useGetMassDelegationQuery,
  useUpdateMassDelegationMutation,
  useConvertMassDelegationMutation,
  useRemoveTiersMutation,
  useAddInvoicesMutation,
  useAddInvoicesLightMutation,
  useAddInvoicesStandardMutation,
} = caseApi;
