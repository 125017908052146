import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../setupStore';

const initialState = {
  value: null,
};

export const ResultMappingHeaderStateSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    resultMappingHeader: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { resultMappingHeader } = ResultMappingHeaderStateSlice.actions;
export const selectResultMappingHeader = (state: RootState) =>
  state.resultMappingHeaderNotification?.value;

export default ResultMappingHeaderStateSlice.reducer;
