import Keycloak from 'keycloak-js';
import React, { useEffect, useState } from 'react';
import Loading from '../shared/components/loading';

const LoadingsRouter = ({
  initialized = false,
  keycloak,
  masterFetched = false,
}: {
  initialized: boolean;
  keycloak: Keycloak;
  masterFetched: boolean;
}) => {
  const [loadingMessage, setLoadingMessage] = useState<string>(
    'loading.not-initialized',
  );

  useEffect(() => {
    if (!initialized) return;
    if (!masterFetched && keycloak.authenticated) {
      setLoadingMessage('loading.master-fetching');
    }
  }, [initialized, masterFetched, keycloak.authenticated]);

  return <Loading message={loadingMessage} />;
};

export default LoadingsRouter;
