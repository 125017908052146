import { format } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translations.json';
import translationFR from './locales/fr/translations.json';

export const getDateLocal = (locale?: string) => {
  switch (locale) {
    case 'en':
    case 'en-US':
      return enGB;
    case 'fr':
    case 'fr-FR':
      return fr;
  }
};

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

/****
 * plurals
 * doc: https://www.i18next.com/misc/json-format#i-18-next-json-v3
 * poc:  https://jsfiddle.net/jdu_itv/w2m7ntzp/3/
 * determine whick key used
 * */

i18n
  .use(detector)
  .use(initReactI18next)
  // passes i18n down to react-i18next
  .init({
    resources,
    compatibilityJSON: 'v4',
    fallbackLng: 'en',
    interpolation: {
      format: function (value: any, formater: any, fallbackLng: any) {
        if (formater === 'numberWithDigits') {
          return Intl.NumberFormat(i18n.languages[0], {
            style: 'decimal',
            minimumFractionDigits: 2,
          }).format(value);
        }
        if (value instanceof Date) {
          return format(value, formater as string, {
            locale: getDateLocal(fallbackLng),
          });
        }
        return value;
      },
      escapeValue: false, // react already safes from xss,
    },
  });

export default i18n;
