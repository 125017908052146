import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { I18nWrapperProps } from '../interfaces/i18n-wrapper-props';

const transComponents = (imgSrc?: string[]) => {
  const source = imgSrc || '';
  const transImg: any = {};
  imgSrc?.forEach((src, index) => {
    const tagName = `img${index + 1}`;
    transImg[tagName] = <img src={src} alt="" />;
  });
  return {
    italic: <i />,
    bold: <strong />,
    span: <span />,
    small: <small />,
    h1: <h1 />,
    h2: <h2 />,
    h3: <h3 />,
    h4: <h4 />,
    h5: <h5 />,
    h6: <h6 />,
    br: <br />,
    article: <article />,
    section: <section />,
    img: <img src={source[0]} alt="" />,
    ...transImg,
    ul: <ul />,
    ol: <ol />,
    li: <li />,
    nbsp: <>&nbsp;</>,
  };
};
const I18nWrapper = (props: I18nWrapperProps): React.JSX.Element => {
  const [prefix, setPrefix] = useState<string>('');

  useEffect(() => {
    if (!props.prefix) return;
    setPrefix(`${props.prefix}.`);
  }, [props.prefix]);

  return (
    <Trans
      i18nKey={prefix + props.translateKey}
      values={props.options}
      components={transComponents(props.imgSrc)}
      transSupportBasicHtmlNodes
    />
  );
};

export default I18nWrapper;
