import React, { cloneElement, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GenericButtonProps } from '../interfaces/GenericButtonProps';
import I18nWrapper from './i18n-wrapper';

const GenericButton = ({
  action = () => {
    // sonar
  },
  active,
  appendElement,
  className,
  disabled,
  heightFitContent = false,
  label = '',
  labelOptions,
  linkStyle = false,
  prependElement,
  style,
  loading = false,
  testid = 'button',
  type = 'button',
  variant = 'primary',
}: GenericButtonProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsActive(active || false);
  }, [active]);

  return (
    <Button
      disabled={disabled || loading}
      size="sm"
      active={isActive}
      type={type}
      variant={variant}
      className={linkStyle ? 'link-button' : className}
      onClick={() => {
        if (!loading) {
          action();
        }
      }}
      aria-label={t(label)}
      data-testid={testid}
      data-qa={testid}
      style={{
        ...style,
        ...(heightFitContent && { height: 'fit-content' }),
      }}
    >
      {!loading && prependElement && <>{cloneElement(prependElement)}</>}
      {loading ? (
        <Spinner className="spinner-icon" animation="border" size="sm" />
      ) : (
        <I18nWrapper translateKey={label} options={{ ...labelOptions }} />
      )}
      {!loading && appendElement && <>{cloneElement(appendElement)}</>}
    </Button>
  );
};

export default GenericButton;
