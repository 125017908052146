import { useMediaQuery } from 'react-responsive';
import utils from '../utils';

const UseIsMobile = () => {
  return useMediaQuery({
    query: `(max-width: ${utils.breakpoints.lg}px)`,
  });
};

export default UseIsMobile;
