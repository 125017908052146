import { AxiosResponse } from 'axios';
import { Reminder } from '../interfaces/reminder';
import { ReminderPreview } from '../interfaces/reminder-preview';
import axiosInstance, { axiosInstanceErrorOn202 } from './axios-instance';
import { toast } from 'react-toastify';
import I18nWrapper from '../components/i18n-wrapper';
import axiosRetry from 'axios-retry';
const getReminderWorkflows = ({
  masterRef,
}: {
  masterRef: string;
  id?: string;
}): Promise<AxiosResponse> =>
  axiosInstance.get(`/api/masters/${masterRef}/reminder-workflows`, {
    withCredentials: true,
  });

const createReminder = ({
  masterRef,
  reminder,
}: {
  masterRef: string;
  reminder: Reminder;
}): Promise<AxiosResponse> =>
  axiosInstance.post(`/api/masters/${masterRef}/reminders`, reminder);

const previewReminder = ({
  masterRef,
  reminderPreview,
}: {
  masterRef: string;
  reminderPreview: ReminderPreview;
}): Promise<ArrayBuffer> =>
  axiosInstance.post(
    `/api/masters/${masterRef}/reminders/preview`,
    reminderPreview,
    { responseType: 'arraybuffer' }
  );

const printLettersByTiers = ({
  masterRef,
  tiersRefs,
}: {
  masterRef: string;
  tiersRefs: string[];
}): Promise<ArrayBuffer> =>
  axiosInstance.post(
    `/api/masters/${masterRef}/reminders/print-letters`,
    { tiersRefs },
    { responseType: 'arraybuffer' }
  );

const printLettersByTiersV2 = ({
  masterRef,
  tiersRefs,
}: {
  masterRef: string;
  tiersRefs: string[];
}): Promise<ArrayBuffer> =>
  axiosInstance.post(`/api/v2/masters/${masterRef}/reminders/print-letters`, {
    tiersRefs,
  });

const printBatchDownload = ({
  batchId,
}: {
  batchId: string;
}): Promise<ArrayBuffer> => {
  axiosRetry(axiosInstanceErrorOn202, {
    retries: 10,
    retryCondition: () => true,
    retryDelay: () => 4000,
  });
  return axiosInstanceErrorOn202
    .get(`/api/v2/print-batches/${batchId}/download`, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
};

const getFileReminder = ({
  url,
  useToastError = true,
}: {
  url: string;
  useToastError?: boolean;
}): Promise<any> => {
  return axiosInstance
    .get(url, {
      responseType: 'arraybuffer',
      transformResponse: [
        function (data, headers) {
          return { data, headers };
        },
      ],
    })
    .catch(() => {
      if (useToastError)
        toast.error(<I18nWrapper translateKey="toast.error" />, {
          position: 'bottom-right',
        });
    });
};

const reminderService = {
  getReminderWorkflows,
  createReminder,
  previewReminder,
  printLettersByTiers,
  printLettersByTiersV2,
  printBatchDownload,
  getFileReminder,
};

export default reminderService;
