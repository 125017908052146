import i18n from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { ButtonGroup, Container } from 'react-bootstrap';
import User from '../interfaces/user';
import GenericButton from './generic-button';

const SwitchLang = ({ user }: { user: User | undefined }) => {
  const languages: string[] = useMemo(() => ['fr', 'en'], []);
  const [currentLanguage, setCurrantLanguage] = useState<string | null>(null);
  const changeLanguage = (lng: string) => {
    setCurrantLanguage(lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => setCurrantLanguage(i18n.languages[0]), [currentLanguage]);

  if (!user || user?.dataLocation !== 'dev') return <></>;

  return (
    <Container>
      <hr />
      <ButtonGroup aria-label="Languages">
        {languages.map((lang) => {
          return (
            <GenericButton
              key={lang}
              {...(currentLanguage?.includes(lang) ? { active: true } : '')}
              label={`button.${lang}`}
              action={() => changeLanguage(lang)}
              testid={`switch_${lang}_button`}
            />
          );
        })}
      </ButtonGroup>
    </Container>
  );
};

export default SwitchLang;
