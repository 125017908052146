import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../setupStore';

export interface InvoiceEntryState {
  value: boolean;
}

const initialState: InvoiceEntryState = {
  value: false,
};

export const InvoiceEntrySlice = createSlice({
  name: 'invoiceEntry',
  initialState,
  reducers: {
    setInvoiceEntry: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { setInvoiceEntry } = InvoiceEntrySlice.actions;

export const selectInvoiceEntry = (state: RootState) =>
  state.invoiceEntry.value;

export default InvoiceEntrySlice.reducer;
