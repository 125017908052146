import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractEventInitialState } from '../../../interfaces/ContractEventInitialState';
import { RootState } from '../../setupStore';

const initialState: ContractEventInitialState = {
  event: null,
};

export const ContractEventSlice = createSlice({
  name: 'contractEvent',
  initialState,
  reducers: {
    setContractEvent: (state, action: PayloadAction<string | null>) => {
      state.event = action.payload;
    },
  },
});

export const { setContractEvent } = ContractEventSlice.actions;

export const selectContractEvent = (state: RootState) => state.contractEvent;

export default ContractEventSlice.reducer;
