import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../setupStore';

const initialState = {
  value: null,
};

export const ResultReplanNotificationStateSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    resultReplan: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { resultReplan } = ResultReplanNotificationStateSlice.actions;

export const selectResultReplan = (state: RootState) =>
  state.resultReplanNotification?.value;

export default ResultReplanNotificationStateSlice.reducer;
