import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../setupStore';

export interface VisibilityState {
  value: boolean;
}

const initialState: VisibilityState = {
  value: false,
};

export const ShowErrorMappingSlice = createSlice({
  name: 'showErrorMapping',
  initialState,
  reducers: {
    setShowErrorMapping: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { setShowErrorMapping } = ShowErrorMappingSlice.actions;

export const selectShowErrorMapping = (state: RootState) =>
  state.showErrorMapping.value;

export default ShowErrorMappingSlice.reducer;
