import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import I18nWrapper from './i18n-wrapper';
import LoadingDots from './loading-dots';

function Loading(props: { message: string; sizeRelativeTo?: string }) {
  return (
    <Container>
      <Row
        className={`d-flex align-items-center ${
          !props.sizeRelativeTo ? 'vh-100' : props.sizeRelativeTo
        }`}
      >
        <Col className="text-center">
          <LoadingDots />
          <p>
            <I18nWrapper translateKey={props.message} />
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Loading;
