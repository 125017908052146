import { RootState } from '../state/setupStore';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

const baseQueryOptions = fetchBaseQuery({
  baseUrl: new URL('/', location.origin).href,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).token;
    const tenantId = (getState() as RootState).tenantId;
    if (token) {
      headers.set('authorization', `Bearer ${token.value}`);
    }

    if (tenantId.value) {
      headers.set('X-TenantId', String(tenantId.value));
    }

    return headers;
  },
});

export default baseQueryOptions;
